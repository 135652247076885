<template>
  <div>

    <!-- Prognosen Chart -->
    <div class="card card-default card-buy-now mb-4" v-if="chart.financialOverview.data">
      <div class="card-header">
        Prognosen der Cannerald AG 2025-2034
      </div>
      <div class="card-body">
        <bar-chart class="w-100" style="height:400px;min-width: 700px" :chart-data="chart.financialOverview.data" :options="chart.financialOverview.options"></bar-chart>
      </div>
    </div>

    <!-- Potentielle Einnahmen Chart -->
    <div class="card card-default card-buy-now mb-4" v-if="chart.potentialEarnings.data">
      <div class="card-header">
        Mögliche Einnahmen eines Genusscheins
      </div>
      <div class="card-body">
        <bar-chart class="w-100" style="height:400px;min-width: 700px" :chart-data="chart.potentialEarnings.data" :options="chart.potentialEarnings.options"></bar-chart>
      </div>
    </div>

    <!-- Beschreibung und Berechnung der kumulierten Einnahmen -->
    <div class="card card-default card-buy-now mt-4">
      <div class="card-header">
        Exit-Berechnung für Genussscheine
      </div>
      <div class="card-body">
        <p>
          Diese Berechnung zeigt Dir mögliche Einnahmen und Exit-Szenarien für Deine Genussscheine der Cannerald AG. Wähle ein Jahr, den geschätzten Unternehmensverkaufswert in Millionen Euro und die Anzahl der Genussscheine, um die kumulierten Einnahmen zu berechnen.
        </p>

        <!-- Auswahl Jahr -->
        <div class="form-group row align-items-center mb-4">
          <label for="year" class="col-sm-3 col-form-label font-weight-bold">Wähle ein Jahr:</label>
          <div class="col-sm-9">
            <select id="year" v-model="selectedYear" class="form-control" @change="calculateResult">
              <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
            </select>
          </div>
        </div>

        <!-- Auswahl Unternehmensverkaufswert -->
        <div class="form-group row align-items-center mb-4">
          <label for="company-value" class="col-sm-3 col-form-label font-weight-bold">Unternehmensverkaufswert:</label>
          <div class="col-sm-9">
            <select id="company-value" v-model="companyValue" class="form-control" @change="calculateResult">
              <option v-for="value in companyValues" :key="value" :value="value">{{ value }} Millionen</option>
            </select>
          </div>
        </div>

        <!-- Eingabe für Anzahl der Genussscheine -->
        <div class="form-group row align-items-center mb-4">
          <label for="plants" class="col-sm-3 col-form-label font-weight-bold">Anzahl der Genussscheine:</label>
          <div class="col-sm-9">
            <input type="number" id="plants" v-model="numberOfShares" min="1" class="form-control" @input="calculateResult" />
          </div>
        </div>

        <!-- Anzeige des Ergebnisses mit Exit -->
        <div v-if="calculationResult !== null" class="mt-3 p-3 border rounded bg-light">
          <p>
            <strong>Ergebnis mit Exit:</strong> Wenn das Unternehmen im Jahr
            <strong><formatted-number :amount="selectedYear" type="numberNatural" animated=""/></strong> für
            <strong><formatted-number :amount="companyValue" type="numberNatural" animated=""/> Millionen Euro</strong> verkauft wird, könnten Deine kumulierten Einnahmen aus den Genussscheinen bei etwa
            <span class="text-primary font-weight-bold" style="font-size: 1.5em;"><formatted-number :amount="calculationResult.withExit" type="fiat" animated=""/></span> liegen.
          </p>
        </div>

        <!-- Anzeige des Ergebnisses ohne Exit -->
        <div v-if="calculationResult !== null" class="mt-3 p-3 border rounded bg-light">
          <p>
            <strong>Ergebnis ohne Exit:</strong> Wenn das Unternehmen im Jahr
            <strong><formatted-number :amount="selectedYear" type="numberNatural" animated=""/></strong> <span class="text-danger">nicht</span> verkauft wird, könnten Deine kumulierten Einnahmen aus den Genussscheinen zum ausgewählten Jahr bei etwa
            <span class="text-warning font-weight-bold" style="font-size: 1.5em;"><formatted-number :amount="calculationResult.withoutExit" type="fiat" animated=""/></span> liegen.
          </p>
          <p>
            Da in diesem Szenario kein Exit stattgefunden hat, könnten weitere Gewinne in den Folgejahren erzielt werden und zu den bisherigen Einnahmen hinzukommen.
          </p>
        </div>

        <!-- Disclaimer -->
        <div class="alert alert-warning mt-4">
          <strong>Hinweis:</strong> Die oben dargestellten Prognosen und Berechnungen basieren auf geschätzten Annahmen und dienen nur zu Informationszwecken. Es gibt <span class="text-danger">keine Garantie</span>, dass diese Prognosen eintreffen. Die tatsächlichen Werte können deutlich <strong>höher</strong> oder <strong>niedriger</strong> ausfallen. Bitte prüfe die Informationen sorgfältig und ziehe gegebenenfalls eine <strong>unabhängige Beratung</strong> hinzu.
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import BarChart from "@/components/Charts/BarChart.vue";

export default {
  components: {
    BarChart,
  },
  data() {
    return {
      term: null,
      product: null,
      amount: 1,
      category: null,
      selectedYear: 2032, // Vorauswahl für das Jahr
      companyValue: 800, // Vorauswahl für Unternehmensverkaufswert in Millionen
      numberOfShares: 1, // Eingabefeld für Anzahl der Genussscheine
      calculationResult: null,
      years: [2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033, 2034],
      companyValues: [300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200, 1300, 1400, 1500], // Unternehmensverkaufswerte in Millionen
      chart: {
        financialOverview: {
          options: null,
          data: null
        },
        potentialEarnings: {
          options: null,
          data: null
        }
      }
    }
  },
  created() {
    this.fillFinancialOverviewChart();
    this.fillPotentialEarningsChart();
    this.calculateResult(); // Initiale Berechnung bei Laden der Seite
  },
  methods: {
    fillFinancialOverviewChart() {
      this.chart.financialOverview.data = {
        labels: ['2025', '2026', '2027', '2028', '2029', '2030', '2031', '2032', '2033', '2034'],
        datasets: [
          {
            label: 'Net Sales',
            backgroundColor: '#4caf50',
            data: [69000000, 80000000, 100000000, 120000000, 130000000, 140000000, 150000000, 160000000, 170000000, 180000000].map(value => value / 1000000)
          },
          {
            label: 'Gross Profit',
            backgroundColor: '#ff9800',
            data: [51000000, 60000000, 72000000, 85000000, 95000000, 105000000, 115000000, 125000000, 135000000, 145000000].map(value => value / 1000000)
          },
          {
            label: 'EBITDA',
            backgroundColor: '#2196f3',
            data: [31000000, 37000000, 43000000, 50000000, 56000000, 62000000, 68000000, 74000000, 80000000, 86000000].map(value => value / 1000000)
          }
        ],
      };

      this.chart.financialOverview.options = {
        legend: {
          display: true
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            ticks: {
              callback: function (value) {
                return value.toLocaleString('de-DE', { style: 'decimal' }) + ' Mio. €';
              },
              autoSkip: true,
              maxTicksLimit: 5
            }
          }]
        }
      }
    },
    fillPotentialEarningsChart() {
      const ebitda = [31000000, 37000000, 43000000, 50000000, 56000000, 62000000, 68000000, 74000000, 80000000, 86000000];

      // Berechnung der potenziellen Einnahmen pro Genusschein
      const potentialEarnings = ebitda.map(value => Math.round((value * 0.4) / 68000));

      // Berechnung der kumulierten Werte
      const cumulativeEarnings = potentialEarnings.reduce((acc, value, index) => {
        const sum = (acc[index - 1] || 0) + value;
        acc.push(sum);
        return acc;
      }, []);

      this.chart.potentialEarnings.data = {
        labels: ['2025', '2026', '2027', '2028', '2029', '2030', '2031', '2032', '2033', '2034'],
        datasets: [
          {
            label: 'Potenzielle Einnahmen in Euro',
            backgroundColor: '#8e44ad',
            data: potentialEarnings
          },
          {
            label: 'Kumulative Einnahmen in Euro',
            backgroundColor: '#2980b9',
            data: cumulativeEarnings
          }
        ],
      };

      this.chart.potentialEarnings.options = {
        legend: {
          display: true
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            ticks: {
              callback: function (value) {
                return value.toLocaleString('de-DE', { style: 'decimal' }) + ' €';
              },
              autoSkip: true,
              maxTicksLimit: 5
            }
          }]
        }
      }
    },

    calculateResult() {
      const selectedEarnings = this.chart.potentialEarnings.data.datasets[1].data[this.years.indexOf(this.selectedYear)];
      const euroCalculation = (this.companyValue * 1000000) * 0.2 / 68000; // Konvertiere Millionen in Euro
      const adjustedEarnings = selectedEarnings * this.numberOfShares; // Anpassung auf Basis der Anzahl der Genussscheine
      this.calculationResult = {
        withExit: adjustedEarnings + euroCalculation * this.numberOfShares,
        withoutExit: adjustedEarnings,
      };
    }
  }
}
</script>
